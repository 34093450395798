import {
  differenceInHours,
  format,
  formatDistanceToNow,
  isValid,
  startOfDay,
  subDays,
  Locale,
  endOfDay,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subMonths,
} from "date-fns";

export interface DateRange {
  after: Date;
  before: Date;
}

export enum DateRanges {
  Today = "today",
  Yesterday = "yesterday",
  Last7Days = "last7days",
  Last30Days = "last30days",
}

export enum ExtendedDateRanges {
  All = "all",
  Today = DateRanges.Today,
  Yesterday = DateRanges.Yesterday,
  LastWeek = "lastweek",
  Last2Weeks = "last2weeks",
  LastMonth = "lastmonth",
  LastQuarter = "lastquarter",
  LastYear = "lastyear",
}

export type FullDateRange = ExtendedDateRanges | DateRanges;

export function parseDateRange(dateRange: FullDateRange): DateRange {
  const now = new Date();
  switch (dateRange) {
    case ExtendedDateRanges.All:
      return {
        after: startOfDay(new Date("2023-01-01")),
        before: now,
      };
    case DateRanges.Last30Days: {
      const thirtyDaysAgo = subDays(now, 30);
      return {
        after: startOfDay(thirtyDaysAgo),
        before: now,
      };
    }
    case ExtendedDateRanges.Today:
      return {
        after: startOfDay(now),
        before: now,
      };
    case ExtendedDateRanges.Yesterday: {
      const yesterday = subDays(now, 1);
      return {
        after: startOfDay(yesterday),
        before: startOfDay(now),
      };
    }
    case DateRanges.Last7Days: {
      const sevenDaysAgo = subDays(now, 7);
      return {
        after: startOfDay(sevenDaysAgo),
        before: now,
      };
    }
    case ExtendedDateRanges.LastWeek: {
      const oneWeekAgo = subDays(now, 7);
      const firstDayOfLastWeek = startOfWeek(oneWeekAgo, { weekStartsOn: 1 });
      const lastDayOfLastWeek = endOfWeek(oneWeekAgo, { weekStartsOn: 1 });

      return {
        after: startOfDay(firstDayOfLastWeek),
        before: endOfDay(lastDayOfLastWeek),
      };
    }
    case ExtendedDateRanges.Last2Weeks: {
      const mondayOf2WeekAgo = startOfWeek(subDays(now, 14), {
        weekStartsOn: 1,
      });
      const sundayOfLastWeek = endOfWeek(now, { weekStartsOn: 1 });

      return {
        after: startOfDay(mondayOf2WeekAgo),
        before: endOfDay(sundayOfLastWeek),
      };
    }
    case ExtendedDateRanges.LastMonth: {
      const prevMonth = subMonths(now, 1);
      const som = startOfMonth(prevMonth);
      const eom = endOfMonth(prevMonth);

      return {
        after: startOfDay(som),
        before: endOfDay(eom),
      };
    }
    case ExtendedDateRanges.LastQuarter: {
      const previousQuarter = Math.floor(now.getMonth() / 3) - 1;
      const year =
        previousQuarter < 0 ? now.getFullYear() - 1 : now.getFullYear();
      const quarter = previousQuarter < 0 ? 3 : previousQuarter;
      const firstDayOfQuarter = new Date(year, quarter * 3, 1);
      const lastDayOfQuarter = new Date(year, quarter * 3 + 3, 0);
      return {
        after: startOfDay(firstDayOfQuarter),
        before: endOfDay(lastDayOfQuarter),
      };
    }
    case ExtendedDateRanges.LastYear: {
      const oneYearAgo = subDays(now, 365);
      return {
        after: startOfDay(oneYearAgo),
        before: now,
      };
    }
    default: {
      const thirtyDaysAgo = subDays(now, 30);
      return {
        after: startOfDay(thirtyDaysAgo),
        before: now,
      };
    }
  }
}

export function getDefaultDateFormat(withTime?: boolean) {
  const helperDate = new Date("2000-01-31").toLocaleDateString();
  switch (helperDate) {
    case "1/31/2000":
    case "01/31/2000":
      return withTime ? "MM/dd/yyyy HH:mm:ss" : "MM/dd/yyyy";
    case "31/1/2000":
    case "31/01/2000":
      return withTime ? "dd/MM/yyyy HH:mm:ss" : "dd/MM/yyyy";
    case "2000-1-31":
    case "2000-01-31":
      return withTime ? "yyyy-MM-dd HH:mm:ss" : "yyyy-MM-dd";
    case "2000年1月31日":
    case "2000年01月31日":
      return withTime ? "yyyy年MM月dd日 HH:mm:ss" : "yyyy年MM月dd日";
    case "2000년 1월 31일":
    case "2000년 01월 31일":
      return withTime ? "yyyy년 MM월 dd일 HH:mm:ss" : "yyyy년 MM월 dd일";
    default:
      return "MM/dd/yyyy";
  }
}

export function formatDateTime(
  value: string | Date,
  withTimeOrFormat?: boolean | string,
) {
  const dateValue = new Date(value);

  if (isValid(dateValue) === false) {
    return value as string;
  }

  if (!withTimeOrFormat) {
    return format(dateValue, getDefaultDateFormat()) as string;
  }
  if (withTimeOrFormat === true) {
    return format(dateValue, getDefaultDateFormat(true)) as string;
  }
  return format(dateValue, withTimeOrFormat) as string;
}

export function withTimeAgo(
  value: string | Date,
  format: string = getDefaultDateFormat(true),
) {
  const dateValue = new Date(value);

  if (isValid(dateValue) === false) {
    return value as string;
  }

  const isInLast24h = differenceInHours(new Date(), dateValue) < 24;

  if (isInLast24h) {
    return formatDistanceToNow(new Date(value)) + " " + "ago";
  }

  return formatDateTime(value, format);
}

interface RelativeTimeOptions {
  locale?: Locale;
  invalidDateText?: string;
}

export function toRelativeTime(
  value: string | Date,
  options?: RelativeTimeOptions,
): string {
  const dateValue = new Date(value);

  if (isValid(dateValue) === false) {
    return options?.invalidDateText ?? (value as string);
  }

  return formatDistanceToNow(dateValue, {
    addSuffix: true,
    locale: options?.locale,
  });
}
