import {
  TemperatureUnitSymbol,
  toTemperature,
  toPercentage,
  tokW,
  tokWh,
  toAmps,
  toVolts,
  toWatts,
  toBoolean,
  toSentenceCase,
  toMiles,
  isNil,
} from "@/utils";

type FieldValue = string | number | boolean | undefined;

type FormattedField = string | undefined | null;

const FIELD_FORMATTERS = {
  ambientTemperature: (
    value: number,
    preferredTemperatureUnit?: TemperatureUnitSymbol,
  ) => toTemperature(value, preferredTemperatureUnit),
  backupReserve: (value: number) => toPercentage(value),
  charge: (value: number) => tokWh(value),
  chargePercentage: (value: number) => toPercentage(value),
  chargeRate: (value: number) => tokW(value),
  chargerCurrent: (value: number) => toAmps(value),
  chargerVoltage: (value: number) => toVolts(value),
  chargerWattage: (value: number) => toWatts(value),
  chargeCompletedAt: (value: string) => value,
  chargeLimit: (value: number) => tokWh(value),
  chargingState: (value: string) => value,
  coolTarget: (
    value: number,
    preferredTemperatureUnit?: TemperatureUnitSymbol,
  ) => toTemperature(value, preferredTemperatureUnit),
  createdAt: (value: string) => value,
  fanMode: (value: string) => toSentenceCase(value),
  gridEnergy: (value: number) => tokWh(value),
  gridPower: (value: number) => tokW(value),
  gridStatus: (value: string) => toSentenceCase(value),
  heatTarget: (
    value: number,
    preferredTemperatureUnit?: TemperatureUnitSymbol,
  ) => toTemperature(value, preferredTemperatureUnit),
  isPluggedIn: (value: boolean) => toBoolean(value),
  isStormModeActive: (value: boolean) => toBoolean(value),
  isStormModeEnabled: (value: boolean) => toBoolean(value),
  operatingMode: (value: string) => value,
  power: (value: number) => tokW(value),
  strategy: (value: string) => toSentenceCase(value),
  whConsumed: (value: number) => tokWh(value),
  range: (value: number) => toMiles(value),
} as const;

export const formatField = (
  fieldName: keyof typeof FIELD_FORMATTERS,
  value: FieldValue,
  preferredTemperatureUnit?: TemperatureUnitSymbol,
): FormattedField => {
  const formatter = FIELD_FORMATTERS[fieldName];
  if (["ambientTemperature", "coolTarget", "heatTarget"].includes(fieldName)) {
    return !isNil(value)
      ? formatter(value as never, preferredTemperatureUnit ?? "F")
      : "—";
  }
  return !isNil(value) ? formatter(value as never) : "—";
};
