import { toDateString } from "@/components/utils";
import { toRelativeTime } from "@/utils/date";

// Define the FormatterFunction type
type FormatterFunction = (
  value: string | number | boolean | Date | null | undefined,
  ...args: unknown[]
) => string | null;

function _camelCaseToWords(string: string) {
  return string?.replace(/([A-Z])/g, " $1").replace(/^./, function (string) {
    return string.toUpperCase();
  });
}

function _snakeCaseToWords(string: string) {
  const processedString = string
    .toLowerCase()
    .split("_")
    .map((word) => ucFirst(word))
    .join(" ");

  return ucFirst(processedString);
}

export function ucFirst(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function truncateMiddle(value: string, length: number = 16): string {
  if (!value) return value;

  // Handle invalid length - return original string for invalid lengths
  if (length < 5) {
    return value;
  }

  // Handle case where specified length is longer than string
  if (length > value.length) {
    return value;
  }

  // For strings under default length with no specified length
  if (value.length <= 16 && length === 16) {
    const totalChars = value.length - 3; // Account for ...
    const leadingChars = Math.ceil(totalChars / 2);
    const trailingChars = Math.floor(totalChars / 2);
    return `${value.slice(0, leadingChars)}...${value.slice(-trailingChars)}`;
  }

  // For strings over specified length
  const totalChars = length - 3; // Account for ...
  const leadingChars = Math.ceil(totalChars / 2);
  const trailingChars = Math.floor(totalChars / 2);
  return `${value.slice(0, leadingChars)}...${value.slice(-trailingChars)}`;
}

export function truncateStart(value: string): string {
  if (!value) return value;
  if (value.length <= 8) return value;
  return `...${value.slice(-8)}`;
}

// Case conversion
export function toSentenceCase(text: string): string {
  if (!text) return text;
  return text.charAt(0).toLocaleUpperCase() + text.slice(1).toLocaleLowerCase();
}
export function toTitleCase(text: string): string {
  if (!text) return text;
  const articles = new Set([
    "a",
    "an",
    "the",
    "and",
    "but",
    "or",
    "for",
    "nor",
    "on",
    "at",
    "of",
    "to",
    "with",
  ]);
  return text
    .toLocaleLowerCase()
    .split(/\s+/)
    .map((word, index) =>
      articles.has(word) && index !== 0
        ? word
        : word.charAt(0).toLocaleUpperCase() + word.slice(1),
    )
    .join(" ");
}

// Number formatting
export const toPercentage = (value: number | null | undefined) =>
  isNil(value) ? null : `${Math.round(value)}%`;
export const toInteger = (value: number | null | undefined) =>
  isNil(value) ? null : `${Math.round(value)}`;
export const toFloat = (value: number | null | undefined) =>
  isNil(value) ? null : `${value.toFixed(1)}`;
export const toNumber = (value: number | null | undefined) =>
  isNil(value) ? null : `${value}`;
export function toPhoneNumber(phone: string): string {
  if (!phone) return phone;
  return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
}

export function isNil(value: unknown): value is null | undefined {
  return value === null || value === undefined;
}

// Energy and power
export const tokWh = (value: number | null | undefined) =>
  isNil(value) ? null : `${(value / 1000).toFixed(1)} kWh`;
export const tokW = (value: number | null | undefined) =>
  isNil(value) ? null : `${(value / 1000).toFixed(1)} kW`;
export const toAmps = (value: number | null | undefined) =>
  isNil(value) ? null : `${value.toFixed(1)} A`;
export const toVolts = (value: number | null | undefined) =>
  isNil(value) ? null : `${Math.round(value)} V`;
export const toWatts = (value: number | null | undefined) =>
  isNil(value) ? null : `${Math.round(value)} W`;

// Distance
export const toMiles = (value: number | null | undefined) =>
  isNil(value) ? null : `${Math.round(value)} mi`;

// Boolean
export const toBoolean = (value: boolean | null | undefined): string | null =>
  isNil(value) ? null : value ? "Yes" : "No";

// Temperature
export type TemperatureUnitString = "FAHRENHEIT" | "CELSIUS";
export const temperatureStringToSymbol = (value: TemperatureUnitString) => {
  if (value === "FAHRENHEIT") {
    return "F";
  }
  return "C";
};
export type TemperatureUnitSymbol = "F" | "C";
export const toTemperature = (
  value: number | null | undefined,
  unit: TemperatureUnitSymbol | TemperatureUnitString = "F",
) => {
  if (value === undefined || value === null) {
    return null;
  }
  // Validate temperature ranges
  const minF = -459.67; // Absolute zero in Fahrenheit
  if ((unit === "F" || unit === "FAHRENHEIT") && value < minF) {
    return `< ${minF}°F`;
  }
  if (unit === "C" || unit === "CELSIUS") {
    const celsius = (value - 32) * (5 / 9);
    const minC = -273.15; // Absolute zero in Celsius
    return celsius < minC ? `< ${minC}°C` : `${Math.round(celsius)}°C`;
  }
  return `${Math.round(value)}°F`;
};

// Secret
export const toSecret = (value: string) => {
  const str = value.toString();
  const lastFour = str.slice(-4);
  const maskedLength = str.length - 4;
  return maskedLength > 0 ? "*".repeat(maskedLength) + lastFour : str;
};

const FormatRegistry = (() => {
  const formatters: Map<string, FormatterFunction> = new Map();

  const register = (key: string, formatter: FormatterFunction) => {
    formatters.set(key, formatter);
  };

  const format = (
    key: string,
    value: string | number | boolean | Date | null | undefined,
    ...args: unknown[]
  ): string | null => {
    const formatter = formatters.get(key);
    if (!formatter) {
      throw new Error(`No formatter registered for key: ${key}`);
    }
    return formatter(value, ...args);
  };

  return { register, format };
})();
FormatRegistry.register(
  "percentage",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "number") {
      return toPercentage(value);
    }
    return null;
  },
);

// Phone number
FormatRegistry.register(
  "phoneNumber",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "string") {
      return toPhoneNumber(value);
    }
    return null;
  },
);

// Case conversion
FormatRegistry.register(
  "titleCase",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "string") {
      return toTitleCase(value);
    }
    return null;
  },
);

// Number formatting
FormatRegistry.register(
  "number",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "string") {
      const parsed = parseFloat(value);
      return Number.isNaN(parsed) ? null : parsed.toString();
    }
    if (typeof value === "number") {
      return value.toString();
    }
    return null;
  },
);

// Energy and power
FormatRegistry.register(
  "kWh",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "number") {
      return tokWh(value);
    }
    return null;
  },
);

FormatRegistry.register(
  "kW",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "number") {
      return tokW(value);
    }
    return null;
  },
);

FormatRegistry.register(
  "amps",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "number") {
      return toAmps(value);
    }
    return null;
  },
);

FormatRegistry.register(
  "volts",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "number") {
      return toVolts(value);
    }
    return null;
  },
);

FormatRegistry.register(
  "watts",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "number") {
      return toWatts(value);
    }
    return null;
  },
);

// Distance
FormatRegistry.register(
  "miles",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "number") {
      return toMiles(value);
    }
    return null;
  },
);

// Date and time
FormatRegistry.register(
  "relativeTime",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "string" || value instanceof Date) {
      return toRelativeTime(value);
    }
    return null;
  },
);

// Boolean
FormatRegistry.register(
  "boolean",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "boolean") {
      return toBoolean(value);
    }
    return null;
  },
);

// Temperature
FormatRegistry.register(
  "temperature",
  (
    value: string | number | boolean | Date | null | undefined,
    ...args: unknown[]
  ) => {
    const isValidUnit = (value: unknown): value is "F" | "C" =>
      typeof value === "string" && ["F", "C"].includes(value);
    const unit = isValidUnit(args[0]) ? args[0] : "F";

    if (typeof value === "number") {
      return toTemperature(value, unit);
    }
    return null;
  },
);

// Secret
FormatRegistry.register(
  "secret",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "string") {
      return toSecret(value);
    }
    return null;
  },
);

// Other
FormatRegistry.register(
  "none",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "string") {
      return value;
    }
    return null;
  },
);

FormatRegistry.register(
  "date",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "string") {
      return toDateString(value, true);
    }
    if (typeof value === "number") {
      return toDateString(value.toString(), true);
    }
    return null;
  },
);

FormatRegistry.register(
  "currency",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "number") {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format(value);
    }
    return null;
  },
);

// Register truncateStart formatter
FormatRegistry.register(
  "truncateStart",
  (value: string | number | boolean | Date | null | undefined) => {
    if (typeof value === "string") {
      return truncateStart(value);
    }
    return null;
  },
);

export const camelCaseToWords = _camelCaseToWords;
export const snakeCaseToWords = _snakeCaseToWords;
export const enumToSentenceCase = (string: string) => {
  if (string.includes("_")) {
    return _snakeCaseToWords(string);
  }

  if (string.toUpperCase() === string) {
    return ucFirst(string.toLowerCase());
  }

  return _camelCaseToWords(string);
};

export type FormatType = Parameters<typeof FormatRegistry.format>[0];

export { FormatRegistry };
